.job-component {
  &__title {
    @include fluid-type($min_width, $max_width, (32.5px/1.6), 32.5px, 1.4, 1.4);
    font-weight: 300;
  }
}

.job {
  position: relative;
  color: $body-color;
  text-decoration: none;

  display: block;
  padding: 1.5rem 4rem 1.5rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid fade_out($green,0.65);

  &:after {
    position: absolute;
    top: 2.3rem;
    right: 0;
    font-family: "werkenbijaccres-icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    content: "\E003";
    width: 40px;
    height: 40px;
    @include fluid-type($min_width, $max_width, (22px/1.6), 22px, 1, 1);
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:before {
    position: absolute;
    top: 2.3rem;
    right: 0;
    background-color: $green;
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
  }

  &__title {
    transition: .35s cubic-bezier(.49,1,.22,1) 0ms;
    h2, .h2 {
      margin-bottom: 0;
    }
  }

  ul.specs {
    margin-bottom: 0;
    border-bottom: none;
  }

  &[class*="bg-"] {
    padding: 1.5rem 5rem 1.5rem 1.5rem;
    border-radius: 1rem;
    border-bottom: none;

    &:after, &:before {
      right: 1.5rem;
    }
  }

  &:hover {
    &:before {
      background-color: $green-light;
      transform: scale(1.05);
    }
    .job__title {
      color: $green-light;
    }
  }
}

@include media-breakpoint-up(md) {
  .job {
    padding: 2rem 4rem 2rem 0;
    margin-bottom: 0;

    &[class*="bg"] {
      padding: 2rem 6rem 2rem 2rem;
      &:after, &:before {
        right: 2rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .job {
    padding: 2rem 6rem 2rem 0;
    &:after {
      width: 60px;
      height: 60px;
    }
    &:before {
      width: 60px;
      height: 60px;
      border-radius: 30px;
    }
    &[class*="bg"] {
      padding: 2rem 7rem 2rem 2rem;
    }
  }
}