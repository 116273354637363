.image-header {
  position: relative;
  z-index: 0;

  &-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    //height: 100%;

    display:grid;
    grid-template-columns: 1fr 12fr 1fr;
    grid-template-areas:
            "left-gutter header-content right-gutter";

    display: -ms-grid;
    -ms-grid-columns: 1fr 12fr 1fr;

    //justify-content: flex-end;
  }
  &__content {
    grid-column: header-content;
    -ms-grid-column: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 3rem;
  }

  &__cta {
    grid-column: header-cta;
    display: none;
  }

  &__background {
    position: relative;
    height: 12vw;
    min-height: 240px;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+20,000000+100&0.2+40,0+100 */
      background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(0,0,0,0.2) 40%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    margin-bottom: 80px;

    color: $white;
  }

  &__title {
    color: $white;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 1rem;
    }
  }

  &__subtitle {
    display: flex;
    align-items: center;
    @include fluid-type($min_width, $max_width, (32.5px/1.5), 32.5px, 1, 1);
    color: $white;
    font-weight: 300;

    &--arrow {
      &:before {
        display: inline-block;
        margin-left: 1rem;
        width: 44px;
        height: 44px;
        min-width: 44px;
        border: 4px solid;
        border-radius: 22px;
        //@extend .gelrekoffie-icons;
        //@extend .gelrekoffie-icons-arrow-next;
        font-size: 11px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        order: 2;
        transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      }

      &:hover {
        &:before {
          transform: translateX(1rem);
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  //specific styling for homepage
  &--home {
    .image-header__background {
      height: 22vw;
      min-height: 300px;
    }
  }

  &--bijbaan {
    .image-header__background:after {
      background: -webkit-gradient(linear,left top,right top,color-stop(60%,#489782),to(rgba(72,151,130,0)));
      background: linear-gradient(90deg,#489782 60%,rgba(72,151,130,0));
      border-bottom: 6px solid hsla(0,0%,93%,.5);
    }
  }
}

@include media-breakpoint-up(sm) {
  .image-header {
    &__cta {
      transform: translateY(50%);
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {

    &-overlay {
      grid-template-areas:
              "left-gutter header-content header-cta right-gutter";
      grid-template-columns: 1fr 8fr 4fr 1fr;
      -ms-grid-columns: 1fr 8fr 4fr 1fr;
    }

    &__cta {
      -ms-grid-column: 3;
      display: flex;
      //justify-content: flex-end;
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-header {

  }
}

@include media-breakpoint-up(xxl) {
  .image-header {
    &--home {

      //.image-header__content {
      //  grid-template-columns: 5fr 3fr 5fr;
      //  -ms-grid-columns: 5fr 3fr 5fr;
      //}
    }
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}
