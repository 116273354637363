.btn {
  display: inline-block;
  padding: .5rem 2rem;
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.1, 1.1);
  color: $white;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-radius: 25px;
  outline: none;

  span {
    margin-left: 0.25rem;
  }

  &-default {
    background-color: $grey-dark;

    &:hover {
      background-color: darken($grey-dark,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &.btn--arrow {
    padding: 0;
    width: 60px;
    height: 60px;
    font-weight: 300;
    text-align: center;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      @include fluid-type($min_width, $max_width, (22px/1.6), 22px, 1, 1);
      vertical-align: middle;
    }
  }

  &-text {
    padding: .4rem 0;
    @include fluid-type($min_width, $max_width, (18px/1.4), 18px, (1.2/1.4), 1.2);
    color: $primary;
    font-weight: normal;
    border-bottom: 1px solid;

    &.btn--arrow {
      &:after {
        border-left: 2px solid $red;
        border-bottom: 2px solid $red;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &-extend {
    display: block;
  }
}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}