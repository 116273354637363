.nav-container {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter navbar right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  background-color: $white;
  width: 100%;
  z-index: 9;
  transition: 0.3s;

  .navbar {
    grid-column: navbar;
    -ms-grid-column: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    position: relative;
    z-index: 1;

    .navbar-brand {
      display: inline-block;
      font-size: 0;
      line-height: 0;
      z-index: 1;
      grid-column: brandlogo;
      -ms-grid-column: 1;

      img {
        max-width: 124px;
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
  z-index: 99;
  display: none;
  grid-column: mainnav;
  -ms-grid-column: 2;

  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (22.5px/1.25), 22.5px, 1, 1);
      color: $secondary;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 96px;
      text-decoration: none;
      transition: 0.3s;
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        display: none;
      }
    }

    //mega submenu
    > .submenu {
      background-color: rgba($white,.95);
      position: absolute;
      top: 100%;
      left: -1rem;
      text-align: left;
      border-bottom: 3px solid $green-light;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin-left: 1rem;
          min-width: 200px;

          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            padding: 0.75rem 0;
            break-inside: avoid;

            a {
              position: relative;
              color: $secondary;
              text-decoration: none;
              transition: 0.3s;
            }

            &.active, &:hover {
              a {
                color: $primary;
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        font-weight: 500;
        border-bottom: 1px solid;
      }
    }

    //open submenu
    &:hover {
      > a:not(.btn) {
        color: $primary;
      }

      .submenu {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      .navbar-brand {
        img {
          //max-width: 74px;
        }
      }
    }
  }

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        //padding: 0 2rem;
        //line-height: 64px;
      }
    }
  }
}


@include media-breakpoint-up(md) {
  .nav-container {
    grid-template-columns: 1fr 12fr 1fr;
    -ms-grid-columns: 1fr 12fr 1fr;
  }
}

@include media-breakpoint-up(lg) {
  body {
    .nav-container {
      &:not(.sticky) {
        //position: absolute;
        //background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        > a {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    &.sticky {
      box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);

      .nav-topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {

    > li {
      padding: 0.3125rem 1.5rem;
      &:first-child {
        padding-left: 0;
      }
    }
  }

  ul#nav-secundary {
    padding-left: 1.5rem;
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .nav-container {
    .navbar {
      height: 90px;

        //display: grid;

        //grid-template-areas:
        //        "brandlogo  searchbox mainnav";
        //grid-template-columns: 1fr auto;
        //justify-content: start;
        //align-items: center;

        //display: -ms-grid;
        //-ms-grid-columns: 1fr auto;

      .navbar-brand {
        img {
          max-width: 162px;
        }
      }
    }
  }

  ul.nav-desktop {
    display: block;
  }
}
@include media-breakpoint-up(xxl) {
  .nav-container {
    .navbar {

    }
  }
}