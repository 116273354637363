.blog {
  display: grid;
  grid-template-columns: 4fr 8fr;
  grid-gap: 1.5rem;

  display: -ms-grid;
  -ms-grid-columns: 4fr 1.5rem 8fr;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
  }
  &:nth-of-type(2) {
    -ms-grid-column: 3;
  }
  &:nth-of-type(3) {
    -ms-grid-column: 5;
  }
  &:nth-of-type(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
  }
  &:nth-of-type(5) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
  }
  &:nth-of-type(6) {
    -ms-grid-column: 5;
    -ms-grid-row: 3;
  }

  &__image {
    -ms-grid-column: 1;
    figure {
      &:after {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+50,000000+100&0+50,0.3+100 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#4d000000', GradientType=0); /* IE6-9 */
        content: "\E003";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        font-family: "werkenbijaccres-icons";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
        text-decoration: none;
        text-transform: none;
        font-size: 0;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        transition: .3s cubic-bezier(.49, 1, .22, 1) 0ms;
      }
    }
  }

  &__text {
    -ms-grid-column: 3;
  }

  &__date {
    margin-bottom: 0.5rem;
    @extend %barlow-condensed;
    @include fluid-type($min_width, $max_width, (15px/1.6), 15px, 1.5, 1.5);
    text-transform: uppercase;

    span:before {
      content: "";
      background-color: $body-color;
      display: inline-block;
      margin-right: 3px;
      width: 3px;
      height: 3px;
      border-radius: 1.5px;
      vertical-align: middle;
    }
  }

  .blog__title {
    margin-bottom: 0.5rem;
    transition: .3s cubic-bezier(.49, 1, .22, 1) 0ms;

    h3, .h3 {
      margin-bottom: 0;
      @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1, 1);
    }
  }

  .blog__description {
    p {
      margin-bottom: 0;
      @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.2875/1.2, 1.2875);
      font-weight: 300;
    }
  }

  &[data-blog-type="news"] {
    .blog__date {
      span {
        color: $blue;
      }
    }
  }

  &[data-blog-type="blog"] {
    .blog__date {
      span {
        color: $green;
      }
    }
  }
}

a.blog {
  position: relative;
  color: $body-color;
  text-decoration: none;

  &:hover {
    .blog__image {
      figure {
        &:after {
          background-color: rgba($primary, 0.8);
          @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 0.9, 0.9);
        }
      }
    }
    .blog__title {
      color: $green-light;
    }
  }
}