.topbar {
  background-color: $secondary;
  display:grid;
  grid-template-columns: 1fr 13fr 0;
  grid-template-areas:
          "left-gutter topbar-wrapper right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 13fr 0;

  max-height: 68px;
  transition: 0.3s;
}

.topbar-wrapper {
  grid-column: topbar-wrapper;
  -ms-grid-column: 2;

  display: grid;
  grid-template-areas: "topsearch hamburger";
  grid-template-columns: auto 1fr;
  align-items: center;

  display: -ms-grid;
  -ms-grid-columns: auto 1fr;

  padding: 0.5rem 0;

  @include fluid-type($min_width, $max_width, (18px/1.125), 18px, (1.2/1.125), 1.2);
}

ul.nav-topbar {
  padding: 0;
  margin: 0;

  grid-area: topnav;
  -ms-grid-column: 1;
  display: none;

  align-items: center;

  li {
    display: inline-block;

    &:not(:last-child):after {
      content: "|";
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 0.9, 0.9);
      color: $white;
    }

    a {
      position: relative;
      display: inline-block;
      padding: 0.5rem 0.75rem;
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 0.9, 0.9);
      color: $white;
      font-weight: 300;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-down(sm) {

}

@include media-breakpoint-up(md) {
  .topbar-wrapper {
    grid-template-areas: "topnav topsearch hamburger";
  }
  ul.nav-topbar {
    display: flex;
  }
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
  .topbar-wrapper {
    grid-template-areas: "topnav topshare topsearch hamburger";
    grid-template-columns: auto auto 1fr;
    -ms-grid-columns: auto auto 1fr;
  }
  .topbar {
    grid-template-columns: 1fr 12fr 1fr;
    -ms-grid-columns: 1fr 12fr 1fr;
  }
}