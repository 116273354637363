nav {
    ul.pagination {
        margin: 3rem 0;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            @extend %barlow-condensed;
            @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1, 1);
            //line-height: 19px;
            font-weight: 300;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.previous, &.next {
            background-color: $green-light;
            border-radius: 18px!important;
            margin: 0 1rem;
            .custom-arrow {
                &:before {
                    color: $white;
                }
            }
        }
        &.previous {
            transform: rotate(-180deg);
        }
        &.next {
            //transform: rotate(225deg);
        }
        a.page-link {
            height: 36px;
            width: 36px;

            border-radius: 18px!important;
            position: relative;
            vertical-align: middle;
        }
        &.disabled {
            a.page-link {
                .custom-arrow {
                    &:after {
                        background-color: $white;
                    }
                    &:before {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

//custom arrow
.custom-arrow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "»";
        @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 0.5, 0.5);
        text-align: center;
    }
}

@include media-breakpoint-up(md) {
    nav {
        ul.pagination {
            margin: 4rem 0;
        }
    }
}