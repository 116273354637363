footer {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter footer-wrapper right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  padding: 2rem 0;
}

.footer-wrapper {
  grid-area: footer-wrapper;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
}

.footer-block {
  margin-bottom: 2rem;
  text-align: center;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(4) {
    -ms-grid-column: 3;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(5) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;

    margin-bottom: 0;
  }

  color: $green;

  a {
    color: $green;
    text-decoration: none;
    transition: 0.3s;

    &.highlighted {
      color: $primary;
    }

    &:hover {
      color: $primary;
    }
  }

  p {
    margin: 0;
  }
}

.footer-logo {
  &__image {
    img {
      max-width: 220px;
    }
  }
}

@include media-breakpoint-up(sm) {

  .footer-wrapper {
    display:grid;
    grid-template-columns: 2fr 2fr;
    column-gap: 2rem;

    display: -ms-grid;
    -ms-grid-columns: 2fr 2rem 2fr;
  }
}

@include media-breakpoint-up(md) {
  footer {
    grid-template-columns: 1fr 12fr 1fr;
    padding: 3rem 0;
    -ms-grid-columns: 1fr 12fr 1fr;
  }

  .footer-wrapper {
      grid-template-columns: 2fr 2fr 2fr;
      -ms-grid-columns: 2fr 2rem 2fr 2rem 2fr;
  }

  .footer-block {
    text-align: inherit;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
      -ms-grid-column-span: 1;
    }
  }
}

@include media-breakpoint-up(xl) {
  .footer-wrapper {
    grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
    -ms-grid-columns: 2fr 2rem 2fr 2rem 2fr 2rem 2fr 2rem 2fr;
  }

  .footer-block {
    margin-bottom: 0;

    &:nth-of-type(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 7;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 9;
      -ms-grid-row: 1;
      -ms-grid-column-span: 1;
    }
  }

  .footer-logo {
    text-align: right;
  }
}