.section {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter section__header right-gutter"
          "left-gutter section__body right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  &__header {
    padding-bottom: 3rem;

    grid-area: section__header;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &__title {
    margin-bottom: 0.5rem;
    @extend %barlow-condensed;
    @include fluid-type($min_width, $max_width, (47.5px/1.6), 47.5px, 0.9, 0.9);
    font-weight: 500;
    text-transform: uppercase;
  }

  &__subtitle {
    @include fluid-type($min_width, $max_width, (32.5px/1.6), 32.5px, 0.9, 0.9);
    font-weight: 300;
  }

  &__link {
    a {
      position: relative;
      @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.4, 1.4);
      color: $secondary;
      font-weight: 700;
      text-decoration: none;
      &:before {
        content: "›";
        margin-right: .5rem;
        @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1, 1);
        font-weight: 600;
        vertical-align: bottom;
      }
    }
  }

  &__body {
    grid-area: section__body;

    -ms-grid-column: 2;
    -ms-grid-row: 1;
    min-width: 100%
  }
}

@include media-breakpoint-up(sm) {
  .section {
    &__link {
      text-align: right;
      &:before {
        margin-right: 1.5rem;
      }
    }
    &__content {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
}