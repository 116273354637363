.text-component {
  display: grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas: "left-gutter text-component__body right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  padding: 2rem 0;

  &__body {
    padding-bottom: 1rem;
    text-align: center;

    grid-area: text-component__body;
    -ms-grid-column: 2;
  }
}

@include media-breakpoint-up(md) {
  .text-component {
    grid-template-columns: 4fr 6fr 4fr;
    -ms-grid-columns: 4fr 6fr 4fr;
    padding: 7rem 0;
  }
}