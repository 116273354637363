
//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";

%barlow {
  font-family: 'Barlow', sans-serif;
}
//light: 300
//regular: 400
//semi-bold: 600
//bold: 700

%barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;
}
//light: 300
//regular: 400
//medium: 500

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);