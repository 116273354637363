.form-wrapper {
  .form-content {
    background-color: $white;
    padding: 1rem;
    border-radius: 1rem;
  }
}

@include media-breakpoint-up(md) {
  .form-wrapper {
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 7fr 1.875rem 5fr;

    .form-content {
      -ms-grid-column: 1;
      padding: 2rem;
    }

    .form-sidebar {
      -ms-grid-column: 3;
    }
  }
}

@include media-breakpoint-up(xl) {
  .form-wrapper {
    .form-content {
      padding: 3rem;
    }
  }
}