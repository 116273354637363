.follow-us {

  .share__icons {
    padding: .5rem 0;
  }
}

.share {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .share__title {
    position: relative;
    padding: $spacer 0;

    @extend %barlow;
    @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1.4/1.6, 1.4);
    color: $white;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    text-align: center;

    li {
      display: inline-block;
      padding: 0;
      text-align: center;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }

      a {
        color: $white;
        text-decoration: none;
        transition: .4s cubic-bezier(.49,1,.22,1) 0ms;

        i {
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 4px;

          width: 32px;
          height: 32px;
          @include fluid-type($min_width, $max_width, (28px/1.3), 28px, 1, 1);
          text-align: center;
          transition: .4s cubic-bezier(.49,1,.22,1) 0ms;
        }

        &:hover {
          color: darken($white, 10%);
        }
      }
    }
  }
  &--page, &--team-member {
    justify-content: flex-end;
    align-items: flex-start;
    grid-column: share;
    -ms-grid-column: 2;
    -ms-grid-row: 3;

    .share__title {
      @extend %barlow;
      @include fluid-type($min_width, $max_width, (16px/1.6), 16px, 1.4/1.6, 1.4);
      color: $body-color;
      text-transform: none;
    }
    ul {
      margin: 0;
      li {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
        a {
          background-color: $green-light;
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          color: $white;
          border-radius: 40px;

          i {
            width: 40px;
            height: 40px;
            @include fluid-type($min_width, $max_width, (20px/1.3), 20px, 2, 2);
          }
        }
      }
    }
  }

  &--page {
    padding-bottom: .5rem;
    .share__title {
      padding-top: 0;
    }
  }

  &--team-member {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 0.25rem!important;
        }
        a {
          width: 34px;
          height: 34px;
          line-height: 34px;
          border-radius: 34px;

          i {
            width: 34px;
            height: 34px;
            @include fluid-type($min_width, $max_width, (17px/1.3), 17px, 2, 2);
          }
        }
      }
    }
  }

  &--topbar {
    display: none;
    grid-area: topshare;
    -ms-grid-column: 2;
    ul {
      margin: 0;
      padding-right: 0.5rem;
      li {
        &:not(:last-child) {
          margin-right: 0!important;
        }
        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          color: $white;
          border-radius: 40px;

          i {
            width: 40px;
            height: 40px;
            @include fluid-type($min_width, $max_width, (22px/1.3), 22px, 2, 2);
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .share {
    ul {
      li {
        &:not(:last-child) {
          margin-right: 4rem;
        }
      }
    }
    &--page, &--team-member {
      -ms-grid-column: 3;
      -ms-grid-row: 1;

      ul {
        margin: 0;
        li {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }

    &--page {
      padding-bottom: 1.5rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .share {
    &--topbar {
      display: block;
    }
  }
}
