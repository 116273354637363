.gallery {
    @include clearfix;
    overflow: hidden;
    margin: 0 -1rem;

    a {
        display: inline-block;
        float: left;
        position: relative;
        width: 50%;
        text-decoration: none;
        padding: 1rem;
        border-bottom: none!important;

        figure {
            border-radius: 1rem;

            &:after {
                content: "\E00A";
                font-family: "werkenbijaccres-icons";
                @include fluid-type($min_width, $max_width, (30px/1.2), 30px, 1.4/1.2, 1.4);
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                line-height: 1;
                text-decoration: inherit;
                text-rendering: optimizeLegibility;
                text-transform: none;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;

                font-smoothing: antialiased;
                background-color: rgba($primary, 0.75);
                position: absolute;
                top: 0;
                left: 0;
                height:100%;
                width: 100%;

                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;

                -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
                transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

                opacity: 0;
            }
        }



        &:hover {
            figure {
                &:after {
                    font-size: calculateRem(65px);
                    opacity: 1;
                }
            }
        }

        &:focus {
            outline: none;
        }
    }

    &.gallery--inline {
        padding: 0.5rem 0;
        border-bottom: 1px solid fade_out($green,0.65);
        a {
            width: 25%;
        }
    }

    &:not(.gallery--inline).one-image {
        a {
            width: 100%;
        }
    }

    &:not(.gallery--inline).three-image {
        a:nth-child(3) {
            width: 100%;
        }
    }
}