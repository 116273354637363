.team-member-component {
  display: block;
  //width: 100%;
  min-width: 100%;
  .owl-item.test {
    z-index: 9;
    .team-member__image {
      figure {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:after {
        right: 0;
        opacity: 1;
        transform: translate(0, -25px);
      }
    }
    .team-member__overlay {
      left: 100%;
      visibility: visible;
      //opacity: 1;
    }
  }
  .owl-item {
    &:hover {
      .team-member__image {
        &:after {
          right: 0;
          opacity: 1;
          transform: translate(0, -25px);
        }
      }
    }
  }
}

.team-member {
  display: block;
  position: relative;
  border-radius: 1rem;
  cursor: pointer;

  transition: 0.3s;

  &__image {
    -ms-grid-row: 1;
    position: relative;
    width: 100%;

    figure {
      border-radius: 1rem;
    }

    &:after {
      $size: 50px;
      content: "‹";
      background: $white;
      position: absolute;
      top: 50%;
      right: 0;
      height: 50px;
      z-index: 9;
      font-size: 38px;
      line-height: 44px;
      color: $green;
      padding-left: 8px;
      padding-right: 8px;
      border-bottom-left-radius: $size * 2;
      border-top-left-radius: $size * 2;

      transition: .2s cubic-bezier(.49,1,.22,1) 0.2s;
      transform: translate(25px,-25px);
      opacity: 0;
    }
  }
  &__text {
    -ms-grid-row: 2;
    position: relative;
    padding: 1rem 0;
    z-index: 1;
    span {
      margin-left: 0.25rem;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    background-color: #fff;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    //opacity: 0;
    visibility: hidden;
    transition: .4s cubic-bezier(.49,1,.22,1) 0ms;

    p {
      @include fluid-type($min_width, $max_width, (17.5px/1.2), 17.5px, 1.2875/1.2, 1.2875);
    }
  }

}

a.team-member {
  color: $body-color;
  text-decoration: none;
  &:hover {
    .team-member__image {
      &:after {
        background-color: rgba($primary,0.8);
      }
    }
  }
}