.usp {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter usp__content right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;

  ul.list {
    li {
      display: block;
    }
  }

  &__content {
    grid-column: usp__content;
    -ms-grid-column: 2;

    margin-bottom: 3rem;
    margin-top: 3rem;

    > div {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 2;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .usp {

    &__content {
      display:grid;
      grid-template-columns: 3fr 3fr 3fr;

      display: -ms-grid;
      -ms-grid-columns: 3fr 3fr 3fr;
    }
  }
}

@include media-breakpoint-up(lg) {
  .usp {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;

    &__content {
      grid-template-columns: 4fr 3fr 4fr;
      -ms-grid-columns: 4fr 3fr 4fr;

      > div {
        &:nth-of-type(1) {
          ul.list {
            li {
              margin-right: 1.25rem;
            }
          }
        }

        &:nth-of-type(3) {
          ul.list {
            li {
              margin-left: 1.25rem;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .usp {
    ul.list {
      padding-left: 0;
    }

    &__content {
      grid-template-columns: 5fr 3fr 5fr;
      -ms-grid-columns: 5fr 3fr 5fr;

      > div {
        &:nth-of-type(1) {
          text-align: right;
          ul.list {
            li {
              display: inline-block;
              margin-right: 2.25rem;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        &:nth-of-type(3) {
          ul.list {
            li {
              display: inline-block;
              margin-right: 2.25rem;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}