.intro-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
          "left-gutter intro-component__body right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 10fr 1fr;
  -ms-grid-rows: auto 1fr;

  &__body {
    grid-area: intro-component__body;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }

  &__title {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    h1, .h1 {
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (47.5px/1.6), 47.5px, 0.9, 0.9);
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__subtitle {
    margin-bottom: 1.5rem;
    @include fluid-type($min_width, $max_width, (32.5px/1.6), 32.5px, 0.9, 0.9);
    font-weight: 300;
  }

  &__left {
    -ms-grid-column: 1;
    margin-bottom: 2rem;
  }
  &__right {
    -ms-grid-column: 3;
  }
}

@include media-breakpoint-up(md) {
  .intro-component {
    &__body {
      column-gap: 4rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .intro-component {
    &__body {
      display:grid;
      grid-template-columns: 6fr 2fr;
      column-gap: 4rem;

      display: -ms-grid;
      -ms-grid-columns: 6fr 4rem 2fr;
    }
    &__left {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .intro-component {
    &__body {
      grid-template-columns: 4fr 2fr;
      -ms-grid-columns: 4fr 4rem 2fr;
    }
  }
}