.news-component {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
          "left-gutter news-component__title right-gutter"
          "left-gutter news-component__items right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  -ms-grid-rows: auto 1fr;
  padding: 2rem 0;

  &__title {
    padding-bottom: 1rem;
    @extend %barlow-condensed;
    @include fluid-type($min_width, $max_width, (90px/1.5), 90px, 1, 1);
    font-weight: normal;
    text-transform: none;
    color: $white;
    grid-area: news-component__title;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-column-span: 3;
  }

  &__items {
    grid-area: news-component__items;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    -ms-grid-column-span: 1;
  }
}

.news {
  margin-bottom: 2rem;

  &:nth-of-type(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  &:nth-of-type(3) {
    -ms-grid-column: 5;
    -ms-grid-row: 3;
    -ms-grid-column-span: 1;
  }

  a {
    color: $body-color;
    text-decoration: none;
  }

  &__image {
    position: relative;
    width: 32%;
    margin: 0 auto;
    z-index: 0;
    transition: .7s cubic-bezier(.49,1,.22,1) 0ms;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: $grey;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      z-index: -1;

      transform: rotate(16deg);
      transform-origin: bottom;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    }
    &:before {
      position: absolute;
      right: -2rem;
      bottom: 0;
      width: 30px;
      height: 30px;
      border: 3px solid;
      border-radius: 15px;
      @extend .werkenbijaccres-icons;
      @extend .werkenbijaccres-icons-arrow-next;
      color: $white;
      font-size: 9px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      z-index: 9;
      opacity: 0;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    }
  }

  &__content {
    @include limit-lines($min_width, $max_width, (18px/1.25), 18px, (1.4/1.25), 1.4, 3);
    color: $white;
    text-align: center;
    p {
      overflow: hidden;
    }
  }

  &__date {
    margin-top: 1.5rem;
    @include fluid-type($min_width, $max_width, (22px/1.5), 22px, (1.6/1.5), 1.6);
    font-weight: bold;
  }

  a:hover {

    .news__image {
      //transform: scale(1.05);

      &:after {
        transform: rotate(0);
      }

      &:before {
        transform: translateX(1.5rem);
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news-component {
    grid-template-columns: 2fr 12fr 2fr;
    -ms-grid-columns: 2fr 12fr 2fr;
    padding: 4rem 0;

    &__items {
      display:grid;
      grid-template-columns: 4fr 4fr 4fr;
      column-gap: 4rem;

      display: -ms-grid;
      -ms-grid-columns: 4fr 4rem 4fr 4rem 4fr;
      -ms-grid-rows: 1fr;
    }
  }

  .news {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .news {
    &__image {
      &:before {
        width: 44px;
        height: 44px;
        border: 4px solid;
        border-radius: 22px;
        font-size: 11px;
        line-height: 36px;
      }
    }
  }
}