html {scroll-behavior: smooth;}

body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

.padding-top--normal {
  padding-top: 1rem;
}

.padding-bottom--normal {
  padding-bottom: 1rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 3rem;
}

.padding-bottom--larger {
  padding-bottom: 3rem;
}

.margin-top--normal {
  margin-top: 1rem;
}

.margin-bottom--normal {
  margin-bottom: 1rem;
}

.margin-offset--normal {
  margin-top: -1rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 3rem;
}

.margin-bottom--larger {
  margin-bottom: 3rem;
}

#timeline-header {
  font-size: 26px;
}

.bg {
  &-blue {
    position: relative;
    background-color: $blue;

    &.bg--small {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
      background: rgba(3,113,185,1); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(3,113,185,1) 15%, rgba(255,255,255,1) 15%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(3,113,185,1) 15%,rgba(255,255,255,1) 15%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(3,113,185,1) 15%,rgba(255,255,255,1) 15%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    &-light {
      position: relative;
      background-color: fade_out($blue, 0.89);

      &.bg--small {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
        background: rgba(3,113,185,0.11); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(3,113,185,0.11) 15%, rgba(255,255,255,1) 15%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(3,113,185,0.11) 15%,rgba(255,255,255,1) 15%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(3,113,185,0.11) 15%,rgba(255,255,255,1) 15%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }
    }
  }

  &-green {
    position: relative;
    background-color: $green;

    &.bg--small {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
      background: rgba(72,151,131,1); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(72,151,131,1) 15%, rgba(255,255,255,1) 15%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(72,151,131,1) 15%,rgba(255,255,255,1) 15%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(72,151,131,1) 15%,rgba(255,255,255,1) 15%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }

    &-light {
      position: relative;
      background-color: fade_out($green, 0.85);

      &.bg--small {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
        background: rgba(72,151,131,0.15); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(72,151,131,0.15) 15%, rgba(255,255,255,1) 15%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(72,151,131,0.15) 15%,rgba(255,255,255,1) 15%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(72,151,131,0.15) 15%,rgba(255,255,255,1) 15%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }
    }
  }
}

.cancelled {
  text-decoration: line-through;
}

.past {
  background: #eee;
}

.text-center {
  text-align: center;
}

.text-green {
  color: $green;
}

.text-green-light {
  color: $green-light;
}

.sidebar {
  position: relative;
}

.site-content {
  a:not(.page-link) {
    color: $green-light;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  ul:not(.specs):not(.pagination) {
    padding-left: 2.75rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;
      &:before {
        position: absolute;
        content: "--";
        margin-left: -1.25rem;
        color: $green-light;
      }
    }
  }
}

.main_element {
  font-weight: bolder;
  color: #FFFFFF !important;
  background: #0196a3;
  border-color: #0196a3 !important;
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($grey-dark, 0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.timeline {
  list-style: none;
  padding: 10px 0 10px;
  position: relative;
  width: 420px;

  &:before {
    background-color: #eee;
    bottom: 0;
    content: " ";
    left: 50px;
    margin-left: -1.5px;
    position: absolute;
    top: 0;
    width: 3px;
  }

  > li {
    margin-bottom: 10px;
    position: relative;

    &:before,
    &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    > .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 100px;
      padding: 20px;
      position: relative;

      .timeline-heading {
        .timeline-panel-controls {
          position: absolute;
          right: 8px;
          top: 5px;

          .timestamp {
            display: inline-block;
          }

          .controls {
            display: inline-block;
            padding-right: 5px;
            border-right: 1px solid #aaa;

            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;

              &:hover {
                color: #333;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }

          .controls + .timestamp {
            padding-left: 5px;
          }
        }
      }
    }

    .timeline-badge {
      background-color: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 50px;
      left: 50px;
      line-height: 52px;
      margin-left: -25px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 50px;
      z-index: 100;
    }

    .timeline-badge + .timeline-panel {
      &:before {
        border-bottom: 15px solid transparent;
        border-left: 0 solid #ccc;
        border-right: 15px solid #ccc;
        border-top: 15px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -15px;
        right: auto;
        top: 26px;
      }

      &:after {
        border-bottom: 14px solid transparent;
        border-left: 0 solid #fff;
        border-right: 14px solid #fff;
        border-top: 14px solid transparent;
        content: " ";
        display: inline-block;
        position: absolute;
        left: -14px;
        right: auto;
        top: 27px;
      }
    }
  }
}

.timeline-badge {
  &.primary {
    background-color: #2e6da4 !important;
  }

  &.success {
    background-color: #0196a3 !important;
  }

  &.warning {
    background-color: #000000 !important;
  }

  &.danger {
    background-color: #d9534f !important;
  }

  &.info {
    background-color: #5bc0de !important;
  }

  &.planning {
    background-color: #00629c !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }
}

.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * { color: #444; }
}

ul.list--default, ul.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list--check {
  padding-left: 1.25rem;
  @include fluid-type($min_width, $max_width, (14px/1.25), 14px, (1.6/1.25), 1.6);
  font-weight: bold;
  text-transform: uppercase;
  li {
    position: relative;
    margin-bottom: 0.65rem;
    &:before {
      position: absolute;
      @extend .werkenbijaccres-icons;
      @extend .werkenbijaccres-icons-check;
      margin-left: -1.25rem;
      color: $primary;
    }
  }
}

ul.list--inline {
  li {
    display: inline-block;
    margin-bottom: 0;
  }
}

main {
  display:grid;
  grid-template-columns: 1fr 12fr 1fr;
  grid-template-areas:
          "left-gutter article right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 12fr 1fr;
  margin-bottom: 2rem;

  article {
    grid-column: article;
    -ms-grid-column: 2;
  }
}

.layout-sidebar-right {
  article {
    header {
      grid-column: header;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }

    section {
      grid-column: section;
      -ms-grid-column: 1;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    aside {
      grid-column: aside;
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }
  }
}

.layout-sidebar-left-right {
  article {
    header {
      grid-column: header;
      -ms-grid-column: 1;
      -ms-grid-column-span: 5;
    }

    .sidebar {
      grid-column: sidebar;
      -ms-grid-column: 1;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    section {
      grid-column: section;
      -ms-grid-column: 3;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    aside {
      grid-column: aside;
      -ms-grid-column: 5;
      -ms-grid-row: 2;
    }
  }
}

.layout-bijbaan {
  margin-bottom: 0;
  padding-bottom: 1rem;
  color: #fff;
  font-size: 120%;
  * {
    color: #fff;
  }
  .alert-success {
    color: #454545;
  }
  .video-wrapper {
    background: transparent;
    border: 3px solid hsla(0,0%,93%,.5);
    border-radius: .5em;
  }
  .gallery a figure {
    background: transparent;
    border: 3px solid hsla(0,0%,93%,.5);
    border-radius: .5em;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: 4rem;
  }
  @include media-breakpoint-down(sm) {
    ~.padding-top--normal {
      padding-top: 2rem;
    }
  }
}

@include media-breakpoint-up(md) {

  .layout--max-width {
    margin: 0 auto;
    max-width: 80%;
  }

  .padding-top--normal {
    padding-top: 4rem;
  }

  .padding-bottom--normal {
    padding-bottom: 4rem;
  }

  .padding-top--large {
    padding-top: 8rem;
  }

  .padding-bottom--large {
    padding-bottom: 8rem;
  }

  .padding-top--larger {
    padding-top: 12rem;
  }

  .padding-bottom--larger {
    padding-bottom: 12rem;
  }

  .margin-top--normal {
    margin-top: 4rem;
  }

  .margin-bottom--normal {
    margin-bottom: 4rem;
  }

  .margin-offset--normal {
    margin-top: -4rem;
  }

  .margin-top--large {
    margin-top: 8rem;
  }

  .margin-bottom--large {
    margin-bottom: 8rem;
  }

  .margin-offset--large {
    margin-top: -8rem;
  }

  .margin-top--larger {
    margin-top: 10rem;
  }

  .margin-bottom--larger {
    margin-bottom: 10rem;
  }

  .margin-offset--larger {
    margin-top: -10rem;
  }

  main {
    margin-bottom: 4rem;
  }

  .layout-sidebar-right {
    article {
      display: grid;
      grid-template-columns: 7fr 5fr;
      column-gap: 4rem;
      grid-template-areas:
              "header header"
              "section aside";

      display: -ms-grid;
      -ms-grid-columns: 7fr 4rem 5fr;

      section {
        margin-bottom: 0;
      }
    }
  }

  .layout-sidebar-left-right {
    article {
      display: grid;
      grid-template-columns: 3fr 6fr 3fr;
      column-gap: 2rem;
      grid-template-areas:
              "header header header"
              "sidebar sidebar sidebar"
              "section section aside";

      display: -ms-grid;
      -ms-grid-columns: 3fr 2rem 6fr 2rem 3fr;

      section {
        margin-bottom: 0;
      }
    }
  }

  .layout-small {
    grid-template-columns: 5fr 8fr 5fr;
    -ms-grid-columns: 5fr 8fr 5fr;
  }
}

@include media-breakpoint-up(lg) {
  .layout-sidebar-left-right {
    article {
      grid-template-areas:
              "header header header"
              "sidebar section aside";
    }
  }
}

@include media-breakpoint-up(xl) {
  .bg {
    &-blue {
      &.bg--small {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
        background: rgba(3,113,185,1); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(3,113,185,1) 30%, rgba(255,255,255,1) 30%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(3,113,185,1) 30%,rgba(255,255,255,1) 30%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(3,113,185,1) 30%,rgba(255,255,255,1) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }

      &-light {
        &.bg--small {
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
          background: rgba(3,113,185,0.11); /* Old browsers */
          background: -moz-linear-gradient(top, rgba(3,113,185,0.11) 30%, rgba(255,255,255,1) 30%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(3,113,185,0.11) 30%,rgba(255,255,255,1) 30%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(3,113,185,0.11) 30%,rgba(255,255,255,1) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        }
      }
    }

    &-green {
      &.bg--small {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
        background: rgba(72,151,131,1); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(72,151,131,1) 30%, rgba(255,255,255,1) 30%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(72,151,131,1) 30%,rgba(255,255,255,1) 30%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(72,151,131,1) 30%,rgba(255,255,255,1) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      }

      &-light {
        &.bg--small {
          /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0371b9+50,ffffff+50 */
          background: rgba(72,151,131,0.15); /* Old browsers */
          background: -moz-linear-gradient(top, rgba(72,151,131,0.15) 30%, rgba(255,255,255,1) 30%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(72,151,131,0.15) 30%,rgba(255,255,255,1) 30%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(72,151,131,0.15) 30%,rgba(255,255,255,1) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0371b9', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        }
      }
    }
  }

  .layout-sidebar-right {
    article {
      grid-template-columns: 8fr 4fr;
      column-gap: 10rem;

      -ms-grid-columns: 8fr 10rem 4fr;

    }
  }

  .layout-sidebar-left-right {
    article {
      grid-template-columns: 2fr 8fr 3fr;
      column-gap: 4rem;

      -ms-grid-columns: 2fr 4rem 8fr 4rem 3fr;

    }
  }
}

.hero-component {
  .location-component {
    grid-row: 1 / span 4;
    grid-column: 1 / span 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    display: none;

    &__image {
      height: 100%;
    }
  }

  .cta {
    grid-row: 5 / span 4;
    grid-column: 1 / span 4;
    -ms-grid-row: 9;
    -ms-grid-row-span: 7;
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    display: none;
  }

  .video-wrapper {
    grid-row: 1 / span 8;
    grid-column: 5 / span 12;
    -ms-grid-row: 1;
    -ms-grid-row-span: 15;
    -ms-grid-column: 9;
    -ms-grid-column-span: 23;
    margin-bottom: 0;
    height: 100%;
    min-height: 210px;
  }

  .cta {
    width: 100%;
    //height: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .hero-component {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr;
    -ms-grid-rows: 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr 2rem 1fr;

    .location-component {
      display: block;
    }

    .cta {
      display: flex;
    }
  }
}

.quicklink {
  background-color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: 0.3s;

  &__image {
    //position: relative;
    -ms-grid-column: 1;

    &:after {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+2,ffffff+70&0+8,0.8+70 */
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 2%, rgba(255,255,255,0) 8%, rgba(255,255,255,0.8) 70%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 8%,rgba(255,255,255,0.8) 70%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255,255,255,0) 2%,rgba(255,255,255,0) 8%,rgba(255,255,255,0.8) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ccffffff',GradientType=0 ); /* IE6-9 */
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    }
    .image-large {
      transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
    }
  }

  &__overlay {

    -ms-grid-column: 2;
    position: relative;
    padding: 1rem;
    z-index: 1;
  }

  &__text {
    margin-top: 6rem;
    margin-bottom: 1rem;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__title {
    margin-bottom: 0.5rem;
    @include fluid-type($min_width, $max_width, (27.5px/1.6), 27.5px, 1, 1);
    font-weight: 600;
  }

  &__description {
    p {
      margin-bottom: 0;
    }
    @include fluid-type($min_width, $max_width, (20px/1.6), 22px, 1.4, 1.4);
    font-weight: 300;
  }


}

a.quicklink {
  color: $body-color;
  text-decoration: none;
}

@include media-breakpoint-up(sm) {
  .quicklink {

    &__text {
      -ms-grid-column: 1;
    }

    &__button {
      -ms-grid-column: 2;
    }
  }
}

@include media-breakpoint-up(md) {
  .quicklink {
    margin-bottom: 0;

    &__overlay {
      padding: 2rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .quicklink {
    &__overlay {
      display: grid;
      grid-template-columns: 1fr auto;

      display: -ms-grid;
      -ms-grid-columns: 1fr auto;

      align-items: flex-end;
    }

    &__text {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .quicklink {
    &__overlay {
      padding: 3rem;
    }
  }
}



