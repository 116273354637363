.filter-component {

  &.sticky-filter {
    position: fixed;
    top: 0;
  }

  &__title {
    margin-bottom: 3rem;
    @include fluid-type($min_width, $max_width, (32.5px/1.6), 32.5px, 1.4, 1.4);
    font-weight: 300;
  }

  &__searchbox {
    margin-bottom: 2rem;
  }

  &__fields {
    h4 {
      margin-top: 2rem;
      margin-bottom: 0.5rem;
    }
  }

  .input-wrapper {
    margin-bottom: 0.5rem;
  }
}