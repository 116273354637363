.cta {
  background-color: $green-light;
  display: flex;
  align-items: center;
  //width: 100%;
  //height: 100%;
  width: 160px;
  height: 160px;
  padding: 1rem;
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (37.5px/1.6), 37.5px, 0.9, 0.9);
  color: $white!important;
  text-decoration: none;
  font-weight: 300;
  text-transform: uppercase;
  border-radius: 1rem;
  transition: .7s cubic-bezier(.49,1,.22,1) 0ms;

  &:hover {
    background-color: darken($green-light,8%);
  }
}

@include media-breakpoint-up(sm) {
  .cta {
    margin-left: auto;
  }
}

@include media-breakpoint-up(lg) {
  .cta {
    width: 200px;
    height: 200px;
    padding: 2rem;
  }
}

@include media-breakpoint-up(xxl) {
  .cta {
    width: 250px;
    height: 250px;
    padding: 2rem;
  }
}