.block-component {
  background-color: $white;
  position: relative;
  display: grid;
  grid-template-columns: 4fr 10fr;

  display: -ms-grid;
  -ms-grid-columns: 4fr 10fr;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: 0.3s;

  &__image {
    position: relative;
    -ms-grid-column: 1;

    &:after {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+50,000000+100&0+50,0.3+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 ); /* IE6-9 */
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    }
    .image-large {
      transition: .7s cubic-bezier(.49, 1, .22, 1) 0ms;
    }
  }
  &__text {
    -ms-grid-column: 2;
    position: relative;
    padding: 1rem;
    z-index: 1;
  }

  &__date {
    background-color: $white;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    align-self: flex-start;
    @include fluid-type($min_width, $max_width, (24px/1.6), 24px, 1.1, 1.1);
    color: $primary;
    text-transform: uppercase;

    + .block-component__title{
      margin-top: 0.5rem;
    }
  }

  &__description {
    margin-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
    @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.4, 1.4);
  }

  &__name {
    margin-bottom: 0.75rem;
    p {
      margin-bottom: 0;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
    opacity: 0;

    figure {
      width: 20%;
      margin: auto auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &--fill {
    background-color: $primary;

    .block-component__text {
      width: 80%;
      color: $white;
    }

    .block-component__title {
      div {
        margin: 0;
      }
    }
  }
}

a.block-component {
  color: $body-color;
  text-decoration: none;
  &:hover {
    .block-component__image {
      &:after {
        background-color: rgba($primary,0.8);
      }
    }
    .block-component__overlay {
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(sm) {
  .block-component {
    grid-template-columns: 3fr 11fr;
    -ms-grid-columns: 3fr 11fr;

    &__image {
      -ms-grid-column: 1;
    }

    &__text {
      -ms-grid-column: 2;
    }
  }
}

@include media-breakpoint-up(md) {
  .block-component {
    grid-template-columns: 6fr 8fr;
    -ms-grid-columns: 6fr 8fr;
    margin-bottom: 0;

    &__text {
      padding: 2rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .block-component {
    grid-template-columns: 4fr 10fr;
    -ms-grid-columns: 4fr 10fr;
  }
}

@include media-breakpoint-up(xl) {
  .block-component {
    grid-template-columns: 3fr 11fr;
    -ms-grid-columns: 3fr 11fr;
  }
}