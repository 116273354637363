.three-c-layout {
  > * {
    margin-bottom: 2rem;
  }

  > * {
    &:nth-of-type(1) {
      -ms-grid-column: 1;
    }

    &:nth-of-type(2) {
      -ms-grid-column: 3;
    }

    &:nth-of-type(3) {
      -ms-grid-column: 5;
    }

    &:nth-of-type(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-of-type(5) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-of-type(6) {
      -ms-grid-column: 5;
      -ms-grid-row: 3;
    }

    &:nth-of-type(7) {
      -ms-grid-column: 1;
    }

    &:nth-of-type(8) {
      -ms-grid-column: 3;
    }

    &:nth-of-type(9) {
      -ms-grid-column: 5;
    }

    &:nth-of-type(10) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-of-type(11) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-of-type(12) {
      -ms-grid-column: 5;
      -ms-grid-row: 3;
    }
  }
}

.four-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

.five-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

.six-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-up(sm) {

  .four-c-layout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

  .five-c-layout {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
    }
  }

  .six-c-layout {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .two-c-layout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 6fr 1.875rem 6fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }

      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }

      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
    }
  }

  .three-c-layout {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr 1.875rem 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 7;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 9;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 9;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 11;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 11;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .three-c-layout {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 5;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 7;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 7;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 7;
      }
    }
  }

  .five-c-layout {
    grid-template-columns: repeat(5, minmax(0, 1fr));

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 9;
        -ms-grid-row: 2;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(13) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(14) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-of-type(15) {
        -ms-grid-column: 9;
        -ms-grid-row: 3;
      }
    }
  }

  .six-c-layout {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 11;
        -ms-grid-row: 1;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .four-c-layout {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 1.875rem;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-of-type(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-of-type(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-of-type(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-of-type(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-of-type(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-of-type(6) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-of-type(7) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-of-type(8) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-of-type(9) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-of-type(10) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-of-type(11) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-of-type(12) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
    }
  }
}
