// Breadcrumbs
$breadcrumb-item-padding:           0.5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $body-color;
$breadcrumb-active-color:           $grey-light;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          $border-radius !default;