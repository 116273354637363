ul.specs {
  list-style: none;
  padding: 0;
  display: inline-block;
  border-bottom: 1px solid $green-light;

  li {
    display: inline-block;
    padding-bottom: 0.5rem;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    i {
      margin-right: 0.5rem;
      @include fluid-type($min_width, $max_width, (22px/1.2), 22px, 1.4/1.2, 1.4);
      color: $green-light;
      vertical-align: middle;
    }
  }
}
