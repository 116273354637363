.page-loader {
  background-color: rgba($white,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  @include transition(all, 0.3s);

  &__wrapper {
    display: flex;
    position: relative;
    width: 84px;
    height: 84px;
    flex-direction: column;
    justify-content: center;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      border: 6px solid $green;
      border-radius: 50%;
      animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $green transparent transparent transparent;
    }
  }

  &__wrapper div:nth-child(1) {
    animation-delay: -0.45s;
  }

  img {
    margin: auto;
    max-width: 64px;
    width: 64px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

body.show-loader {
  .page-loader {
    opacity: 1;
    z-index: 99;
  }
}