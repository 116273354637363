.tabs {
  display: flex;
  color: black;
  vertical-align: middle;
  align-items: center;
}

.tab-container {
  width: 100%;
}

.tab-component {
  display: none;
}

.nav-tabs  {
  display: flex;
  color: grey;
  border-bottom: none;
  .nav-link {
    padding: 1rem 1.8rem;
    border: none;
    border-radius: 0;

    &.active{
      background-color: transparent;
      color: $black;
      border-left: 1px solid rgba(0,0,0,0.5);
      border-right: 1px solid rgba(0,0,0,0.5);
    }
    &:not(.active){
      color: rgba(#8d8994,50%);
      border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    &:last-child {
      border-right: none;
      flex: 1;
    }
  }
  .nav-link:hover {
    background-color: transparent;
    color: $primary;
  }
}

.tab-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.accordion {
  .card {
    background-color: transparent;
    margin-bottom: 1.5rem;
    border: none;
    &-header {
      position: relative;
      background-color: transparent;
      height: 30px;
      border-color: rgba(0,0,0,0.5);
      cursor: pointer;

      &:hover {
        color: $green-light;
      }

      .custom-arrow {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        transform: rotate(-90deg);
        transition: all .5s ease;

        &:before {
          content: "›";
          width: 30px;
          height: 30px;
          @include fluid-type($min_width, $max_width, (42.5px/1.6), 42.5px, 0.5, 0.5);
        }
      }
      &[aria-expanded="true"] {
        .custom-arrow {
          transform: rotate(90deg);
          &:before {
            color: $primary;
          }
        }
      }
    }
    &-body {
      padding: 0.5rem 0;
    }
  }
}


@include media-breakpoint-up(md) {
  .tab-component {
    display: block;
    .accordion {
      display: none;
    }
  }
}