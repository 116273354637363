.location-component {
  display: block;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;

  transition: 0.3s;

  &__image {
    -ms-grid-row: 1;
    position: relative;
    width: 100%;
    padding-top: 100%;

    figure {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-radius: 1rem;
    }

    &:after {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+50,000000+100&0+50,0.3+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 50%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 50%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 ); /* IE6-9 */
      content: "\E00B";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      font-family: "werkenbijaccres-icons";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
      text-decoration: none;
      text-transform: none;
      font-size: 0;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      transition: .3s cubic-bezier(.49,1,.22,1) 0ms;
    }
  }
  &__text {
    -ms-grid-row: 2;
    position: relative;
    padding: 1rem;
    z-index: 1;
    span {
      margin-left: 0.25rem;
    }
  }

  &--highlighted {
    .location-component__title {
      background-color: $green-light;
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (37.5px/1.6), 37.5px, 0.9, 0.9);
      color: $white!important;
      text-decoration: none;
      font-weight: 300;
      text-transform: uppercase;

      border-radius: 1rem;
      transition: .7s cubic-bezier(.49,1,.22,1) 0ms;
      width: 100%;
      padding-top: 100%;
      position: relative;
      span {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 2rem;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
    .location-component__text {
      visibility: hidden;
    }
  }
}

a.location-component {
  color: $body-color;
  text-decoration: none;
  &:hover {
    .location-component__image {
      &:after {
        background-color: rgba($primary,0.8);
        @include fluid-type($min_width, $max_width, (37.5px/1.6), 37.5px, 0.9, 0.9);
      }
    }
  }
}